import react from 'react';
import ReactDOM from 'react-dom'
import NavBar from './components/NavBar';
import Hero from './components/Hero';
import Cat from './components/Cat';
import OurPackages from './components/OurPackages';
import Footer from './components/Footer';
import {BrowserRouter,Routes,Route, useLocation} from 'react-router-dom'
import AboutUs from './assets/pages/AboutUs';
import Home from './components/Home';
import ContactUs from './assets/pages/Refund';
import Cities from './assets/pages/Cities'
import Packages from './assets/pages/Packages'
import OurServices from './assets/pages/OurServices'
import SIGNUP from './assets/pages/Registration'
import FinalPage from './components/FinalPage';
import FinalCat2 from './components/FinalCat2';
import Payment from './components/Payment';
function App() {
  return (
    <div>
      <BrowserRouter>
      <NavBar/>  
      <Routes>
          <Route path="/" element={<Home/> }/>
          <Route path="/AboutUs" element={<AboutUs/> } />
          <Route path="/ContactUs" element={<ContactUs/> }/>
          <Route path="/Cities" element={<Cities/> } />
          <Route path="/Packages" element={<Packages/> }/>
          <Route path="/OurServices" element={<Cat/> }/>
          <Route path="/SIGNUP" element={<SIGNUP/> }/>
          <Route path="/FinalPage" element={<FinalPage/>}/>
          <Route path="/FinalCat2" element={<FinalCat2/>}/>
          <Route path="/Payment" element={<Payment/>}/>
      </Routes>
      <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
