import React from 'react'
import Hero from './Hero'
import Cat from './Cat'
//import OurPackages from './OurPackages'
import Packages from '../assets/pages/Packages'

function Home() {
  return (
    <div>
      <Hero/>
      <Packages/>
      <Cat/>
    </div>
  )
}

export default Home
