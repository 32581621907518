import React from 'react'
import cattemple from "../assets/images/temple/cattemple.jpg"
import catmountain1 from "../assets/images/mountain/catmountain1.jpg"
import ScubaGoa from "../assets/images/water/ScubaGoa.jpg"
import Honey from "../assets/images/HoneyMoon/Honey.jpg"
import {Link,navigate} from "react-router-dom"

function Cat() {
  return (
    <div className='py-[15px] border border-black'> 
        <h1 className='text-gray-950 md:h-full border-spacing-2 mb-10 text-4xl bold'>
          Our categories </h1>
        <div className="grid md:grid-cols-4 gap-20 md:gap-6 ml-5 mr-0">
            <div className='shadow-xl h-[300px]  hover:scale-110 duration-100'> 
            <img src={cattemple} alt=" " className='inline'  class="object-fill h-48 w-96"/>
            <Link to="/Packages" className="btn btn-primary 'text-black align-middle ml-[100px]">Temple</Link>
            </div>
            <div className='shadow-xl h-[300px]  hover:scale-110 duration-100 object-cover'>
            <img src={catmountain1} alt=" " className='inline'  class="object-fill h-48 w-96"/>
            <Link to="/Packages" className="btn btn-primary 'text-black align-middle ml-[100px]">Mountain</Link>       
              </div>
            <div className='shadow-xl h-[300px]  hover:scale-110 duration-100'>
            
            <img src={ScubaGoa} alt=" " className='inline'  class="object-fill h-48 w-96"/>
            <Link to="/Packages" className="btn btn-primary 'text-black align-middle ml-[100px]">Water Adventure</Link>
            </div>
            <div className='shadow-xl h-[300px]  hover:scale-110 duration-100 mb-6'>
            <img src={Honey} alt=" " className='inline'  class="object-fill h-48 w-96"/>
            <Link to="/Packages" className="btn btn-primary 'text-black align-middle ml-[100px]">Destinaions</Link>
            </div>
        </div>
    </div>
  )
}

export default Cat
