import React from 'react'
import qr from "../../src/assets/images/payment/ashupayment.jpeg"

const Payment = () => {
  return (
    <div>
       <div className="grid md:grid-cols-2 gap-20 md:gap-6 ml-5 mr-0 mb-100 mt-5">
            <div className='shadow-xl h-[600px]  hover:scale-100 duration-100'> 
            <img src={qr} alt=" " className='inline'  class="object-fill h-50 w-96 mt-3 mb-20"/>
            </div>
            
            <div>
      <iframe
  src="https://docs.google.com/forms/d/e/1FAIpQLSdv_zpKvJyptVG93ijMUnTB7pELxHM0OXytYFtOg5iFpRNyXA/viewform?embedde``d=true"
  width="640"
  height="685"
  frameborder="0"
  marginheight="0"
  marginwidth="0"
>
  Loading…
</iframe>
    </div>
            
        </div>
      
    </div>
  )
}

export default Payment
