import React from 'react'
import {data} from "../../components/Data/data"
import PackageDesign from './PackageDesign'

const Packages = () => {
  window.scrollTo({top:0,behavior:'smooth'});
  return (
    <div className='py-[15px] border border-black'>
      <h1 className='text-gray-950 md:flex border-spacing-2 mb-10 text-4xl bold'>Our Packages </h1>
      <div className="grid md:grid-cols-4 gap-10 md:gap-6 ml-5">
        {
          data.map((val)=>{
            return(
              <PackageDesign pdata={val}/>
            )
          })
        }
      </div>
      
    </div>
    
  )
}

export default Packages

