import pack1 from '../../../src/assets/images/packages/GRANDISLAND.png'
import pack2 from '../../../src/assets/images/packages/HotAir.png'
import pack3 from '../../../src/assets/images/packages/Bungee.jpeg'
import pack4 from '../../../src/assets/images/packages/DoodhSagar.png'
import pack5 from '../../../src/assets/images/packages/DinnerAtCruze.jpg'
import pack6 from '../../../src/assets/images/packages/para.png'
import pack7 from '../../../src/assets/images/packages/flyboarding.png'
import pack8 from '../../../src/assets/images/packages/Boat.png'
import pack9 from '../../../src/assets/images/packages/NorthGoa.png'
import pack10 from '../../../src/assets/images/packages/SouthGOA.png'
import pack11 from '../../../src/assets/images/HomeStay/hm1.jpeg'
import hmf1 from '../../../src/assets/images/HomeStay/hm2.jpeg'
import hmf2 from '../../../src/assets/images/HomeStay/hm3.jpeg'
import hmf3 from '../../../src/assets/images/HomeStay/hm4.jpeg'
import hmf4 from '../../../src/assets/images/HomeStay/hm5.jpeg'
import hmf5 from '../../../src/assets/images/HomeStay/hm6.jpeg'
import hmf6 from '../../../src/assets/images/HomeStay/hm7.jpeg'
import hmf7 from '../../../src/assets/images/HomeStay/hm8.jpeg'
import hmf8 from '../../../src/assets/images/HomeStay/hm9.jpeg'
import hmf9 from '../../../src/assets/images/HomeStay/hm10.jpeg'
import hmf10 from '../../../src/assets/images/HomeStay/hm11.jpeg'
import hmf11 from '../../../src/assets/images/HomeStay/hm12.jpeg'
import hmf12 from '../../../src/assets/images/HomeStay/hm13.jpeg'
import hmf13 from '../../../src/assets/images/HomeStay/hm14.jpeg'
import hmf14 from '../../../src/assets/images/HomeStay/hm15.jpeg'
import hmf15 from '../../../src/assets/images/HomeStay/hm16.jpeg'
import pack12 from '../../../src/assets/images/HomeStay/lchm1.png'
import lchmf1 from '../../../src/assets/images/HomeStay/lchm2.png'
import lchmf2 from '../../../src/assets/images/HomeStay/lchm3.png'
import lchmf3 from '../../../src/assets/images/HomeStay/lchm4.png'
import lchmf4 from '../../../src/assets/images/HomeStay/lchm5.png'
import lchmf5 from '../../../src/assets/images/HomeStay/lchm6.png'
import lchmf6 from '../../../src/assets/images/HomeStay/lchm7.png'
import lchmf7 from '../../../src/assets/images/HomeStay/lchm8.png'
import lchmf8 from '../../../src/assets/images/HomeStay/lchm9.png'
import lchmf9 from '../../../src/assets/images/HomeStay/lchm10.png'
import lchmf10 from '../../../src/assets/images/HomeStay/lchm11.png'
import lchmf11 from '../../../src/assets/images/HomeStay/lchm12.png'
import lchmf12 from '../../../src/assets/images/HomeStay/lchm13.png'
import lchmf13 from '../../../src/assets/images/HomeStay/lchm14.png'
import lchmf14 from '../../../src/assets/images/HomeStay/lchm15.png'

import vdo1 from '../../../src/assets/videos/scuba/scuba1.mp4'
import vdo2 from '../../../src/assets/videos/scuba/scuba2.mp4'
import vdo3 from '../../../src/assets/videos/scuba/scuba3.mp4'
import vdo4 from '../../../src/assets/videos/scuba/scuba4.mp4'
import vdo5 from '../../../src/assets/videos/scuba/scuba5.mp4'
import vdo6 from '../../../src/assets/videos/scuba/scuba6.mp4'
import vdt1 from '../../../src/assets/videos/scuba/test1.mp4'
import vdt2 from '../../../src/assets/videos/scuba/test2.mp4'
import vdt3 from '../../../src/assets/videos/scuba/test3.mp4'
import vdt4 from '../../../src/assets/videos/scuba/test4.mp4'
export const data=[
        {
            id:1,
            cat:1, //Goa Water
            name:"GRAND ISLAND SCUBA DIVING WITH WATER SPORTS",
            price:"1400/PERSON",
            image:pack1,
            des:"Package Includes",
            des1:"Basic : 1400/Person, Premium: 2000/person", 
            des2:"FREE PICK UP AND DROP FROM COMMON LOCATION /BAGGA/CALUNGATE/CANDOLIM/SINQERIUM/ARPORA BETWEEN  7:30   AM TO  8:00 AM",
            des3:"38 KM BOAT JOURNEY FOR GRAND ISLAND",
            des4:"5 MIN TRAINING IN SHALLOW WATER",
            des5:"20 TO 25 MIN SCUBA DIVING UNDER WATER ALONG WITH PADI INSTRUCTOR",
            des6:" LIGHT BREAKFAST AND  LUNCH  VEG/NON VEG IN BOAT ",
            des7: "BEERS ONE/SOFT DRINKS /WATER",
            des8: "tALL 5 WATER SPORTS  BANANA ,BUMPER ,JETSKI,PARASAILING,SPEED BOAT RIDE,DOLPHIN SIGHT SEEING ",
            vd1:vdo1,
            vd2:vdo2,
            vd3:vdo3,
            vd4:vdo4,
            vd5:vdo5,
            vd6:vdo6,
            test1:vdt1,
            test2:vdt2,
            test3:vdt3,
            test4:vdt4,

        },
        {
            id:2,
            cat:1, 
            name:"HOT AIR BALOON RIDES",
            price:"15000/PERSON",
            image:pack2,
            des:"Package Includes",
            des1:"BRIEFING BY YOUR PILOT",
            des2:"SAFTY GUIDELINES",  
            des3:"40 MIN TO 1 HOUR SAILING ON BALLON ",
            des4:"TEA COFFEE AND LIGHT REFRESH MENTS",
            des5:"LIGHT REFRESH MENTS",
            des6:"MAXIMUM CAPACITY OF SHARED FLIGHT IS  8 PEOPLE",
            des7:"PRIVATE FLIGHT ACCOMODATES MAXIMUM THREE PEOPLE",
            des8:"ACTIVITY STARTS FROM MID NOVEMBER TO MAY ",
            vd1:vdo1,
            vd2:vdo2,
            vd3:vdo3,
            vd4:vdo4,
            vd5:vdo5,
            vd6:vdo6,
            test1:vdt1,
            test2:vdt2,
            test3:vdt3,
            test4:vdt4,
        },
        {
            id:3,
            cat:1, 
            name:"BUNJEE JUMP",
            price:"3200/Person",
            image:pack3,
            des:"Package Includes",
            des1:"SELF  ARRIVAL TO THE BUNJEE LOCATION  AFTER PAYING BOOKING AMOUNT  TIMING 10:00 TO 16:30", 
            des2:"WEIGHT LIMIT 40 KGS TO 110 KGS",
            des3:"AGE 12 YEARS TO 50 YEARS . AFTER 50 YEARS NEEDS MEDICAL CERTIFICATE",
            des4:"ATTIRE SHOES AND TROUSERS ARE RECOMMENDED",
            des5:"DSLR VIDEO PICS AND JUMP CERTIFICATE INCUDED",
            des6:"DRONE SHOT AVAILABLE ON EXTRA PRICE OF  1000",
            des7:"PEOPLE WITH HEART PROBLEM/SPONDYLITIS/RECENT FRACTURE/EPILEPSY/OSTEOPOROSIS/HIGH BLOOD PRESSURE/BACK ACHE/ANY DISLOCATIONS",
            des8:"PREGNANT WOMENS ARE NOT ALLOWED TO JUMP",
            vd1:vdo1,
            vd2:vdo2,
            vd3:vdo3,
            vd4:vdo4,
            vd5:vdo5,
            vd6:vdo6,
            test1:vdt1,
            test2:vdt2,
            test3:vdt3,
            test4:vdt4,
        },
        {
            id:4,
            cat:1, 
            name:"DOODH SAGAR WATER FALL",
            price:"1700/Person",
            image:pack4,
            des:"Package Includes",
            des1:"FREE PICK UP AND DROP FROM BAGGA/CALUNGATE/ARPORA/SINQERIUM/CANDOLIM",
            des2:"40 MIN  BUS JOURNEY TO ONAM",
            des3:"JUNGLE SAFARI TO THE DOODHSAGAR WATERFALL",
            des4:"ONE HOUR STAY AT THE WATERFALL",
            des5:"LUNCH AT SPICE GARDEN",
            des6:"OLD GOA SIGHT SEEING",
            vd1:vdo1,
            vd2:vdo2,
            vd3:vdo3,
            vd4:vdo4,
            vd5:vdo5,
            vd6:vdo6,
            test1:vdt1,
            test2:vdt2,
            test3:vdt3,
            test4:vdt4,
        },
        {
            id:5,
            cat:1, 
            name:"DINNER AT CRUISE",
            price:"1500/Person",
            image:pack5,
            des:"Package Includes",
            des1:"REE PICK UP AND DROP FROM COMMON LOCATION BAGA/CANDOLIM/CALUNGATE/SINQUERIUM/ARPORA BETWEEN 7:30  AM TO 8:00 AM",
            des2:"42 HOURS CRUISING IN MANDOVI RIVER FROM 8:45PM TO 10:45 PM",
            des3:"BUFFET DINNER",
            des4:"FOLK DANCES",
            des5:"LIVE DJ",
            des6:"DANCE FLOOR",
            des7:"DRINKS 2 PEG /2 BEERS",
            vd1:vdo1,
            vd2:vdo2,
            vd3:vdo3,
            vd4:vdo4,
            vd5:vdo5,
            vd6:vdo6,
            test1:vdt1,
            test2:vdt2,
            test3:vdt3,
            test4:vdt4,
        },
        {
            id:6,
            cat:1, 
            name:" PARAGLIDING IN GOA",
            price:"3700/PERSON",
            image:pack6,
            des:"Package Includes",
            des1:"TENDOM JOYRIDE CLASSIC 3700/PERSON  ( 10 MIN DURATION)",
            des2:"ADVENTURES JOYRIDE 4200/PERSON( 15 MIN DURATION)",
            des3:"AGE 12 YEARS TO 50 YEARS . AFTER 50 YEARS NEEDS MEDICAL CERTIFICATE",
            des4:"ATTIRE SHOES AND TROUSERS ARE RECOMMENDED",
            des5:"DSLR VIDEO PICS AND JUMP CERTIFICATE INCUDED ",
            des6:"DRONE SHOT AVAILABLE ON EXTRA PRICE OF  1000",
            des7:"PEOPLE WITH HEART PROBLEM/SPONDYLITIS/RECENT FRACTURE/EPILEPSY/OSTEOPOROSIS/HIGH BLOOD PRESSURE/BACK ACHE/ANY DISLOCATIONS",
            des8:"PREGNANCY ARE NOT ALLOWED TO JUMP",
            vd1:vdo1,
            vd2:vdo2,
            vd3:vdo3,
            vd4:vdo4,
            vd5:vdo5,
            vd6:vdo6,
            test1:vdt1,
            test2:vdt2,
            test3:vdt3,
            test4:vdt4,
        },
        {
            id:7,
            cat:1, 
            name:"FLY BOARDING",
            price:"2800/PERSON",
            image:pack7,
            des:"Package Includes",
            des1:"PRETRAINING BY QUALIFIED INSTRUCTORS",
            des2:"2800/Person",
            des3:"15 MIN  FLYBOARDING",
            des4:"CUSTOMER HAS TO REACH THE LOCATION AFTER ONLINE BOOKING",
            des5:"ALL Safety Gears",
            des6:"Go for Quality",
            des7:"Feel the thrill",
            des8:"PREGNET Women Not Allowed",
            vd1:vdo1,
            vd2:vdo2,
            vd3:vdo3,
            vd4:vdo4,
            vd5:vdo5,
            vd6:vdo6,
            test1:vdt1,
            test2:vdt2,
            test3:vdt3,
            test4:vdt4,
        },
        {
            id:8,
            cat:1, 
            name:"WATER SPORTS",
            price:"1300/PERSON",
            image:pack8,
            des:"Package Includes",
            des1:"5 WATER SPORTS IN CALUNGATE /BAGGA BEACH ",
            des2:"PARASAILING ",
            des3:"JET SKI RIDE",
            des4:"BUMPER RIDE",
            des5:"BANANA RIDE",
            des6:"SPEED BOAT RIDE",
            des7:"Best Price",
            des8:"Go for Quality",
            vd1:vdo1,
            vd2:vdo2,
            vd3:vdo3,
            vd4:vdo4,
            vd5:vdo5,
            vd6:vdo6,
            test1:vdt1,
            test2:vdt2,
            test3:vdt3,
            test4:vdt4,
        },
        {
            id:9,
            cat:1, 
            name:"WATER SPORTS NORTH GOA",
            price:"500/PERSON",
            image:pack9,
            des:"Package Includes",
            des1:"FREE PICK UP AND DROP FROM BAGGA/CALUNGATE/ARPORA/SINQERIUM/CANDOLIM",
            des2:"VISIT TO THE HIGHLIGHTED LOCATIONS ON SHARED AC COACH",
            des3:"SNOW PARK CHARGES EXTRA",
            des4:"FORT AGUADA",
            des5:"BAGA BEACH",
            des6:"ANJUNA BEACH",
            des7:"Best Price",
            des8:"Go for Quality",
            vd1:vdo1,
            vd2:vdo2,
            vd3:vdo3,
            vd4:vdo4,
            vd5:vdo5,
            vd6:vdo6,
            test1:vdt1,
            test2:vdt2,
            test3:vdt3,
            test4:vdt4,
        },
        {
            id:10,
            cat:1, 
            name:"SOUTH GOA",
            price:"500/PERSON",
            image:pack10,
            des:"Package Includes",
            des1:"FREE PICK UP AND DROP FROM BAGGA/CALUNGATE/ARPORA/SINQERIUM/CANDOLIM",
            des2:"VISIT TO THE HIGHLIGHTED LOCATIONS ON SHARED AC COACH ",
            des3:"DOLPHIN SHOW AT MIRAMAR BEACH CHARGES EXTRA ",
            des4:"MIRAMAR BEACH",
            des5:"BANANA RIDE",
            des6:" SE CATHEDRAL CHURCH",
            des7:"Best Price",
            des8:"Go for Quality",
            vd1:vdo1,
            vd2:vdo2,
            vd3:vdo3,
            vd4:vdo4,
            vd5:vdo5,
            vd6:vdo6,
            test1:vdt1,
            test2:vdt2,
            test3:vdt3,
            test4:vdt4,
        },
        {
            id:11,
            cat:2, //Homestay
            name:"DDHYANA HOME STAY GOA",
            price:"2400/PERSON",
            image:pack11,
            des:"Package Includes",
            des1:"Delux Room Single Occupancy - Rs 2400/- Double Occupancy - Rs. 2500/- Delux Room X Single Occupancy - Rs. 2500/ - Double Occupancy - Rs. 2600/- ",
            des2:"Superior room Single Occupancy   - 2750/- Double Occupanay - Rs. 2990/-",
            des3:"find peace and balance in your life",
            des4:"At Ddhyana, you'll also have the opportunity to explore Goa's beautiful beaches and culture",
            des5:"Our location is conveniently located near many popular tourist attractions such as Panjim Church , Miramar Beach , Donapaula , Floating Casino boats , Dolphin rides so you can experience all that Goa has to offer while still enjoying a peaceful retreat",
            hm1:hmf1, 
            hm2:hmf2,
            hm3:hmf3,
            hm4:hmf4,  
            hm5:hmf5, 
            hm6:hmf6,
            hm7:hmf7,
            hm8:hmf8,
            hm9:hmf9, 
            hm10:hmf10,
            hm11:hmf11,
            hm12:hmf12,
            hm13:hmf13, 
            hm14:hmf14,
            hm15:hmf15,
            vd1:vdo1,
            vd2:vdo2,
            vd3:vdo3,
            vd4:vdo4,
            vd5:vdo5,
            vd6:vdo6,
            test1:vdt1,
            test2:vdt2,
            test3:vdt3,
            test4:vdt4,
        },
        {
            id:12,
            cat:2, //Homestay
            name:"RAMGARH LION CAVE HOME STAY",
            price:"10500/PERSON",
            image:pack12,
            des:"Package Includes",
            des1:"Day 1 -> Arrival to the homestay by self car or private cab . Private cab charges one way 3000 . Pick up from haldwani railway station/kathgodam railway station /bareilly airport Pantnagar airport",           
            des2:"Enjoy pahari style breakfast, lunch and dinner  on chargeable basis",
            des3:"Explore the peaches belt and nearby sight seeing by doing evening walk Pahadi thal 700, Veg Thal 500, Non Veg Thal 700",
            des4:"Day 2 Enjoy breakfast in homestay Explore the nearby Golu Dev Temple / kainchi dham temple by self car or private cab. Private cab charges : 1600",
            des5:"Day 3 Enjoy breakfast at homestay Explore the scenic beauty of beautiful hill station Mukteshwar  by self car or private cab . Private cab charges  : 1000",
            des6:"Day 4 Enjoy breakfast Check out at 10:00 am from the homestay  by self car or private car Total Package including pick up and drop : 19100 Total Package excluding pick up and drop : 10500+ lunch dinner extra, chargeable basis",
            hm1:lchmf1, 
            hm2:lchmf2,
            hm3:lchmf3,
            hm4:lchmf4,  
            hm5:lchmf5, 
            hm6:lchmf6,
            hm7:lchmf7,
            hm8:lchmf8,
            hm9:lchmf9, 
            hm10:lchmf9,
            hm11:lchmf10,
            hm12:lchmf11,
            hm13:lchmf12, 
            hm14:lchmf13,
            hm15:lchmf14,
            vd1:vdo1,
            vd2:vdo2,
            vd3:vdo3,
            vd4:vdo4,
            vd5:vdo5,
            vd6:vdo6,
            test1:vdt1,
            test2:vdt2,
            test3:vdt3,
            test4:vdt4,
        }
    ]
