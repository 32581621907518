import React from 'react'
import {useLocation} from "react-router-dom"
import {data} from "../components/Data/data"
import {Link,useNavigate} from "react-router-dom"
import ReactPlayer from 'react-player'

function FinalPage() {
  window.scrollTo({top:0,behavior:'smooth'});
  const location=useLocation();
  const navigate=useNavigate();
  const ID=location.state.id;
  const found=data.find((item)=>item.id===ID)
  const openInNewTab = (url) => {
    window.open(url, "_self");
  };
  
  return (
    
    <div className='py-[15px] border border-black'>
        <h1 className='text-gray-950 md:flex border-spacing-2 mb-10 text-3xl bold'>{found.name} </h1>
        <div className="grid md:grid-cols-3 gap-10 md:gap-6 ml-5">
            <div className='w-full mx-w-md mx-auto hover:scale-105 duration-100'> 
            <img src={found.image} alt=" " className='inline'class="object-fill h-50 w-96"/>
            <h1 className='text-black align-middle ml-[100px]'> 
            ₹ {found.price}
            </h1>
            </div>
            <div className='shadow-xl h-[300px] flex hover:scale-105 duration-100 '>       
            <h1 className='text-black align-left ml-[10px]'> 
                <h1 className='text-gray-800 border-spacing-2 mb-3 text-2xl bold underline italic'>To Book Package Click Below</h1>
                <div className='gap-50 mt-8 ml-5'>
            <button onClick={()=>openInNewTab("https://api.whatsapp.com/send?phone=9368045629")} className='text-white bg-black ml-[80px] p-2 rounded mt-[10px] h-10'>Book Now</button>
            <a href="https://api.whatsapp.com/send?phone=9368045629">
              <h1 className='bold mt-10 ml-10 text-black'>For Discount Chat with us</h1>
            <svg
                class="w-20 h-20 text-green-400 fill-current ml-20 mb-30 mt-3"
                xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 500 600">
               <path
                 d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
          ></path>
          </svg>
              </a>
              </div>
               
            </h1>
            </div>



            <div className='shadow-xl h-[300px]  hover:scale-105 duration-100'>       
            <h1 className='text-black align-left ml-[10px] mb-1'> 
                <h1 className='text-gray-800 md:flex border-spacing-2 mb-3 text-2xl bold underline italic'>{found.des}</h1>
                <ul class="list-disc text-[13px] ml-2 text-red-950">
                          <li>{found.des1}</li>
                          <li>{found.des2}</li>
                          <li>{found.des3}</li>
                          <li>{found.des4}</li>
                          <li>{found.des5}</li>
                          <li>{found.des6}</li>
                </ul>
               
            </h1>
            </div>  
        </div>
        <div class="border-solid border-2 border-indigo-600 mt-[30px]" ></div>
        
        <div className='py-[15px] border border-black'>
        <h1 className='text-gray-950 md:flex border-spacing-2 mb-10 text-4xl bold'>Featured Images </h1>
        <div className="max-w-[1240px]  mx-auto md:grid grid-cols-5 gap-6">
        <div className='shadow-xl h-[300px]  hover:scale-105 duration-100'> 
                <img src={found.hm1} alt=" " className='inline'  class="object-fill h-60 w-90"/>
                </div>
                <div className='shadow-xl h-[300px]  hover:scale-110 duration-100'> 
                <img src={found.hm2} alt=" " className='inline'  class="object-fill h-60 w-96"/>
                </div>
                <div className='shadow-xl h-[300px]  hover:scale-110 duration-100'> 
                <img src={found.hm3} alt=" " className='inline'  class="object-fill h-60 w-96"/>
                </div>
                <div className='shadow-xl h-[300px]  hover:scale-110 duration-100'> 
                <img src={found.hm4} alt=" " className='inline'  class="object-fill h-60 w-96"/>
                </div>
                <div className='shadow-xl h-[300px]  hover:scale-110 duration-100'> 
                <img src={found.hm5} alt=" " className='inline'  class="object-fill h-60 w-96"/>
                </div>
                <div className='shadow-xl h-[300px]  hover:scale-110 duration-100'> 
                <img src={found.hm6} alt=" " className='inline'  class="object-fill h-60 w-96"/>
                </div>
                <div className='shadow-xl h-[300px]  hover:scale-110 duration-100'> 
                <img src={found.hm7} alt=" " className='inline'  class="object-fill h-60 w-96"/>
                </div>
                <div className='shadow-xl h-[300px]  hover:scale-110 duration-100'> 
                <img src={found.hm8} alt=" " className='inline'  class="object-fill h-60 w-96"/>
                </div>
                <div className='shadow-xl h-[300px]  hover:scale-110 duration-100'> 
                <img src={found.hm9} alt=" " className='inline'  class="object-fill h-60 w-96"/>
                </div>
                <div className='shadow-xl h-[300px]  hover:scale-110 duration-100'> 
                <img src={found.hm10} alt=" " className='inline'  class="object-fill h-60 w-96"/>
                </div>
                <div className='shadow-xl h-[300px]  hover:scale-110 duration-100'> 
                <img src={found.hm11} alt=" " className='inline'  class="object-fill h-60 w-96"/>
                </div>
                <div className='shadow-xl h-[300px]  hover:scale-110 duration-100'> 
                <img src={found.hm12} alt=" " className='inline'  class="object-fill h-60 w-96"/>
                </div>
                <div className='shadow-xl h-[300px]  hover:scale-110 duration-100'> 
                <img src={found.hm13} alt=" " className='inline'  class="object-fill h-60 w-96"/>
                </div>
                <div className='shadow-xl h-[300px]  hover:scale-110 duration-100'> 
                <img src={found.hm14} alt=" " className='inline'  class="object-fill h-60 w-96"/>
                </div>
                <div className='shadow-xl h-[300px]  hover:scale-110 duration-100'> 
                <img src={found.hm15} alt=" " className='inline'  class="object-fill h-60 w-96"/>
                </div>
                
        </div>
        
       </div>
        
        <div class="border-solid border-2 border-indigo-600 mt-[10px]" ></div>

        
        
        <div className='py-[15px] border border-black'>
        <h1 className='text-gray-950 md:flex border-spacing-2 mb-10 text-4xl bold'>Featured Videos </h1>
        <div className="max-w-[1240px]  mx-auto md:grid grid-cols-4 gap-6">
            <div className='shadow-xl h-[300px]  hover:scale-110 duration-100 ml-5'> 
            <ReactPlayer controls={true} url={found.vd6} height="250px" width="200px object-fit"/>
            </div>
            <div className='shadow-xl h-[300px]  hover:scale-110 duration-100 object-cover'>
            <ReactPlayer controls={true} url={found.vd2} height="250px" width="200px object-fit"/>        
            </div>
            <div className='shadow-xl h-[300px]  hover:scale-110 duration-100'>
            <ReactPlayer controls={true} url={found.vd3} height="250px" width="200px object-fit"/> 
            
            </div>
            <div className='shadow-xl h-[300px]  hover:scale-110 duration-100'>
            <ReactPlayer controls={true} url={found.vd5} height="250px" width="200px object-fit"/> 
            </div>
        </div>

        <div class="border-solid border-2 border-indigo-600 mt-[10px]" ></div>

        <div className='py-[15px] border border-black'>
        <h1 className='text-gray-950 md:flex border-spacing-2 mb-10 text-4xl bold'>Testimonials</h1>
        <div className="max-w-[1240px] mx-auto md:grid grid-cols-4 gap-6">
            <div className='shadow-xl h-[300px]  hover:scale-110 duration-100 ml-5'> 
            <ReactPlayer controls={true} url={found.test1} height="250px" width="200px object-fit"/> 
            </div>
            <div className='shadow-xl h-[300px]  hover:scale-110 duration-100 ml-5'> 
            <ReactPlayer controls={true} url={found.test2} height="250px" width="200px object-fit"/> 
            </div>
            <div className='shadow-xl h-[300px]  hover:scale-110 duration-100 ml-5'> 
            <ReactPlayer controls={true} url={found.test3} height="250px" width="200px object-fit"/> 
            </div>
            <div className='shadow-xl h-[300px]  hover:scale-110 duration-100 ml-5'> 
            <ReactPlayer controls={true} url={found.test4} height="250px" width="200px object-fit"/> 
            </div>
        </div>

        <div class="border-solid border-2 border-indigo-600 mt-[10px]" ></div>

     </div>       
    </div>
    </div>
  )
}

export default FinalPage
