import React from 'react'
import {FaFacebook,FaTwitter,FaInstagram,FaYoutube,FaWhatsapp} from 'react-icons/fa'

function Footer() {
  return (
    <div className='bg-gray-900 text-white'>
        <div className='md:flex md:justify-between sm:px-12 px-12 bg-[#ffffff19 md:item-center py-8]' >
        Copyright @ Funtours Travel Express
        </div>
        <div className='gap-6 flex justify-between my-4 border-l-amber-200 mx-auto mt-10 ml-10 mr-20'>
            <FaTwitter className="icon  h-10 w-10"/>
            <FaFacebook className="icon h-10 w-10"/>
            <FaInstagram className="icon h-10 w-10"/>
            <FaYoutube className="icon h-10 w-10" />
            <FaWhatsapp className="icon h-10 w-10"/>
        </div>
        <div className='flex justify-between mt-10'> 
            <ul className='flex justify-between gap-20 mx-auto mb-7'>
                <li>About</li>
                <li>Packages</li>
                <li>Contact</li>
                <li>Chat with Us</li>
 
            </ul> 
            
         </div>
      
    </div>
  )
}

export default Footer
