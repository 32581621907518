import React from 'react'
import city1 from '../images/Cities/city1.jpeg'
function Cities() {
  return (
    <div>
       <div className="grid md:grid-cols-4 gap-20 md:gap-6 ml-5 mr-0">
            <div className='shadow-xl h-[300px]  hover:scale-110 duration-100'> 
            <img src={city1} alt=" " className='inline'  class="object-fill h-48 w-96"/>
            <h1 className='text-black align-middle ml-[100px]'> 
                GOA
            </h1>
            </div>
            
        </div>
      
    </div>
  )
}

export default Cities
