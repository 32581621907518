import React from 'react'

function SIGNUP() {
  return (
    <div>
      <iframe
  src="https://docs.google.com/forms/d/e/1FAIpQLSdv_zpKvJyptVG93ijMUnTB7pELxHM0OXytYFtOg5iFpRNyXA/viewform?embedde``d=true"
  width="640"
  height="685"
  frameborder="0"
  marginheight="0"
  marginwidth="0"
>
  Loading…
</iframe>
    </div>
  )
}

export default SIGNUP
