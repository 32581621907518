import React from 'react'
import about1 from '../images/Aboutus/About1.jpeg'
function AboutUs() {
  return (
    <div>
       <div className="grid md:grid-cols-3 gap-20 md:gap-6 ml-5 mr-0">
            <div className='shadow-xl h-[300px]  hover:scale-110 duration-100'> 
            <img src={about1} alt=" " className='inline'  class="object-fill h-50 w-96 mt-3"/>
            </div>
            <div className='shadow-xl h-[300px]  hover:scale-105 duration-100'> 
            <h1 className='text-black align-left ml-[50px] mt-8 mr-8 text-justify'> 
            FUNTOURS TRAVEL EXPRESS  IS AN 
            ONLINE AUTHORISED BOOKING AGENCY FOR DIFFERENT WATERSPORTS AND ADVENTURE ACTIVITIES. WE VALUE OUR CUSTOMERS AND PROVIDE THEM  ONLINE BOOKING FACILITY FOR  
            VARIOUS WATERSPORTS ACTIVITIES SUCH AS SCUBA DIVING, BANANA RIDE AS PER OUR CATALOGUE. FOR MORE DETAILS CONTACT US ON
            9368045629
            </h1>
            </div>
            <div className='shadow-xl h-[300px]  hover:scale-110 duration-100'> 
            <img src={about1} alt=" " className='inline'  class="object-fill h-50 w-96 mt-3"/>
            </div>
            
        </div>
      
    </div>
  )
}

export default AboutUs
