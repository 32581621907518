import React from 'react'
import refund from '../images/REFUND/REFUND.jpeg'

function ContactUs() {
  return (
    <div>
    <div className="grid md:grid-cols-3 gap-20 md:gap-6 ml-5 mr-0">
         <div className='shadow-xl h-[300px]  hover:scale-110 duration-100'> 
         <img src={refund} alt=" " className='inline'  class="object-fill h-50 w-96 mt-3"/>
         </div>
         <div className='shadow-xl h-[300px]  hover:scale-105 duration-100'> 
         <h1 className='text-black align-left ml-[50px] mt-8 mr-8 text-justify'> 
        <li>
        Booking amount for any package is non refundable.
        </li>
        <li>
        The trip date can be shifted to the next date with cancellation charge 200/person.
        </li>  
        <li>
          In case of natural calamities such as high tides if the activity cancels then  50% of  the booking amount is refundable  
        </li>
         
         </h1>
         </div>
         <div className='shadow-xl h-[300px]  hover:scale-110 duration-100'> 
         <img src={refund} alt=" " className='inline'  class="object-fill h-50 w-96 mt-3"/>
         </div>
         
     </div>
   
 </div>
  )
}

export default ContactUs
