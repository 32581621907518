import React, { useState } from 'react'
import {AiOutlineClose,AiOutlineMenu} from 'react-icons/ai'
import {Link} from 'react-router-dom'
import {FaCartPlus} from 'react-icons/fa'
import img from '../assets/images/hero/logo192.png'

function NavBar() {
  const [menu,setMenu]=useState(false)
  const handleMenu=()=>{
    setMenu(!menu)
  }
  return (
    <div className='bg-black p4 text-white items-center mt-[16px] h-[50px]' >
      <div className='max-w-[1240] border border-white flex justify-between mt-[100]'>
                
                <div className='flex'> 
                <img src={img} alt=" " className='ml-1' />
                  <h1 className='w-full text-3xl font-bold text-[#00df9a] ml-[0px] mt-[3px]'>Funtours</h1>
                </div>
                {
                  menu?
                  <AiOutlineClose onClick={handleMenu} className='text-white text-3xl md:hidden block'/>:
                  <AiOutlineMenu onClick={handleMenu} className='text-white text-3xl md:hidden block'/>
                }
                
            <ul className='hidden md:flex' >
                  <Link to="/"> <li className='p-3' >Home</li></Link>
                  <Link to="Cities"><li className='p-3'>Cities</li></Link> 
                  <Link to="Packages"><li className='p-3'>Packages</li></Link>
                  <Link to="OurServices"><li className='p-3'>OurServices</li></Link>
                  <Link to="contactus"> <li className='p-3'>Refund Policy</li></Link>
                  <Link to="AboutUs"> <li className='p-3'>AboutUS</li></Link>
                  <Link to="SIGNUP"> <li className='p-3'>Registration</li></Link>
                  <Link to="Packages"> <li className='p-3'><FaCartPlus/></li></Link>
            </ul> 
            {/* for mobile menu */}
            <ul className={`duration-300 fixed bg-[#503737] top-[65px] w-full h-screen 
            ${menu?'left-[0%]':'left-[-100%]'}`}>
              <Link to="/"onClick={handleMenu} className='text-white text-2xl md:hidden block'> <li className='p-3' >Home</li></Link>
                  <Link to="Cities" onClick={handleMenu} className='text-white text-2xl md:hidden block'><li className='p-3' >Cities</li></Link> 
                  <Link to="Packages" onClick={handleMenu} className='text-white text-2xl md:hidden block'><li className='p-3'>Packages</li></Link>
                  <Link to="OurServices" onClick={handleMenu} className='text-white text-2xl md:hidden block'><li className='p-3'>OurServices</li></Link>
                  <Link to="contactus" onClick={handleMenu} className='text-white text-2xl md:hidden block'> <li className='p-3'>Refund Policy</li></Link>
                  <Link to="AboutUs" onClick={handleMenu} className='text-white text-2xl md:hidden block'> <li className='p-3'>AboutUS</li></Link>
                  <Link to="SIGNUP" onClick={handleMenu} className='text-white text-2xl md:hidden block'> <li className='p-3'>Registration</li></Link>
            </ul>
      </div>
    </div>
  )
}

export default NavBar

