import React from 'react'
import {Link,useNavigate} from "react-router-dom"

const PackageDesign = (props) => {
  const navigate=useNavigate();
    const {id,name,price,image,cat}=props.pdata
    
  return (
        
            <div className='w-full mx-w-md mx-auto hover:scale-105 duration-100'> 
            <img src={image} alt=" " className='inline'class="object-fill h-48 w-96"/>
            <h1 className='text-black align-middle ml-[100px] mt-1'> 
            ₹{price}
            </h1>
            <h1 className='text-red-800 text-justify align-middle ml-[30px] mb-1 mt-1'> 
            {name}
            </h1>
            {
                cat==1?<button onClick={()=>navigate("/FinalPage",{state:{id:id}})} className='text-white bg-black ml-[100px] p-3 rounded mt-[30px]' >Book Now</button> :
                <button onClick={()=>navigate("/FinalCat2",{state:{id:id}})} className='text-white bg-black ml-[100px] p-3 rounded mt-[30px]'>Book Now</button>
            }
            </div>
  )
}

export default PackageDesign
